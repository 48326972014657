body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.column {
    float: left;
    width: 25%;
    margin-bottom: 16px;
    padding: 0 8px;
    align-items: center;
}

.column-p {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 8px;
    align-items: left;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
}

.row {
    float: left;
    width: 100%;
    margin-bottom: 16px;
    padding: 0 8px;

}

.about-section {
    padding: 20px;
    text-align: left;
    background-color: #383899;
    color: rgb(206, 32, 32);
    align-items: left;

}

.about-team-header {
    padding: 40px;
    text-align: center;
    color: rgb(218, 18, 18);
    padding: 0 70px;
    text-decoration: overline;

}

.about-paragraph {
    padding: auto;
    text-align: justify;
    text-size-adjust: inherit;
    font-size: 17px;
    color: rgb(47, 147, 177);
    /* background-image: url("./Mdmc_images/mdmc_collage.jpg"); */

}

.about-paragraph-1 {
    padding: auto;
    text-align: justify;
    text-size-adjust: inherit;
    font-size: 17px;
    color: rgb(118, 231, 127);

}

.photo-team {
    width: 200px;
    height: 200px;
    border: 1px;
    border-style: ridge;
    border-color: #000;
}

.photo-collage {
    width: 100%;
    height: 100%;


}

.container-p {
    padding: 10px;
    float: left;
    width: 100%;
    margin-bottom: 16px;
    padding: 40 40px;
    background-color: #272729;
}

.container {
    padding: 0 16px;
    align-items: center;
}

.container::after,
.row::after {
    content: "";
    clear: both;
    display: table;
}

.title {
    color: grey;
}

.button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.button:hover {
    background-color: #555;
}

@media screen and (max-width: 650px) {
    .column {
        width: 100%;
        display: block;
    }
}