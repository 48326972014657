* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
}

html {
    background: #111111;
    /* background-image: url(https://s3.postimg.org/s1n3ji1ur/paper_fibers_2_X.png); */
    /* background: "../images/cranes-construction-site.jpg"; */
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.subtle {
    color: rgb(15, 14, 14);
}

.pageBackgroundImage {
    /* background-image: url("./Mdmc_images/cranes-construction-site.jpg"); */
    background: #111111;

}

.iconImage {
    /* border-radius: 50%;*/
    border: 5px solid rgb(177, 187, 31);

}

.main-card--cointainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 600px));
    grid-gap: 10px;
    justify-content: space-around;
    /* background-image: url("./Mdmc_images/cranes-construction-site.jpg"); */

}

.card-container {
    margin: 15px auto 0;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-around;
    align-items: flex-start;

}

.card-container img {
    max-width: 250px;
    height: 300px;
}

.card {
    background-color: #fff;
    padding: 10px;
    position: relative;
    box-shadow: 0 0 5px rgba(75, 75, 75, 0.07);
    z-index: 1;
}

.card-body {
    display: inline-block;
}

.card-number {
    margin-top: 15px;
}

.card-circle {
    border: 1px solid #aaa;
    border-radius: 50%;
    display: inline-block;
    line-height: 22px;
    font-size: 12px;
    height: 25px;
    text-align: center;
    width: 25px;
}

.card-author {
    display: block;
    font-size: 12px;
    letter-spacing: 0.5px;
    margin: 15px 0 0;
    text-transform: uppercase;
}

.card-title {
    font-family: "Cormorant Garamond", serif;
    font-size: 60px;
    font-weight: 300;
    line-height: 60px;
    margin: 10px 0;
    text-transform: capitalize;
}

.card-description {
    display: inline-block;
    font-weight: 300;
    line-height: 22px;
    margin: 10px 0;
}

.card-read {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 6px;
    margin: 5px 0 20px;
    position: relative;
    text-align: right;
    text-transform: uppercase;
}

.card-read:after {
    background-color: #b8bddd;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 9px;
    width: 75%;
}

.card-tag {
    float: right;
    margin: 5px 0 0;
    border: 1px solid #aaa;
    padding: 5px 10px;
    cursor: pointer;
}

.card-tag:hover {
    background: #aaa;
    color: black;
}

.card-media {
    max-width: 100%;
    height: auto;
}

.card-shadow {
    background-color: #fff;
    box-shadow: 0 2px 25px 2px rgba(0, 0, 0, 1), 0 2px 50px 2px rgba(0, 0, 0, 1),
        0 0 100px 3px rgba(0, 0, 0, 0.25);
    height: 1px;
    margin: -1px auto 0;
    width: 80%;
    z-index: -1;
}

@media screen and (max-width: 998px) {
    .main-card--cointainer {
        display: grid;
        grid-template-columns: repeat(1, minmax(200px, 500px));
        grid-gap: 50px;
        justify-content: space-around;

    }
}

/* ---------- Button styles ------------------ */

.navbar {
    padding-top: 10px;
    text-align: center;
    /* background-image: url("./Mdmc_images/cranes-construction-site.jpg"); */
}

.navbar h1 {
    padding-bottom: 20px;
    text-transform: capitalize;
}

/** button group styles **/
.btn-group {
    border-radius: 1rem;
    text-transform: capitalize;
}

.btn-group__item {
    border: none;
    min-width: 6rem;
    padding: 0.5rem 2rem;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    margin: 0;
    font-size: 15px;
    box-shadow: inset 0px 0px 0px -15px rebeccapurple;
    transition: all 300ms ease-out;
    text-transform: capitalize;
}

.btn-group__item:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.btn-group__item:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.btn-group__item:hover,
.btn-group__item:focus {
    color: rebeccapurple;
    box-shadow: inset 0px -20px 0px -15px rebeccapurple;
}

.btn-group__item:focus {
    outline: none;
}

.btn-group__item:after {
    content: "✔️";
    margin-left: 0.5rem;
    display: inline-block;
    color: rebeccapurple;
    position: absolute;
    transform: translatey(10px);
    opacity: 0;
    transition: all 200ms ease-out;
}

.btn-group__item--active:after {
    opacity: 1;
    transform: translatey(-2px);
}

.footer {
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
}